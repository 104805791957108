import styled, { css } from "styled-components";
import { Container as Wrapper } from "../../styles/UI";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;
`;

export const Container = styled(Wrapper)`
  .cta {
    position: relative;
    z-index: 10;
    margin-top: 45px;

    h2 {
      font-weight: normal;
      font-size: 25px;
      line-height: 42px;
      letter-spacing: -0.01em;
      margin-top: 16px;
    }

    h1 {
      font-size: 35px;
      font-weight: 600;
      letter-spacing: -0.01em;
      margin-bottom: 24px;
      line-height: 1.25;
    }

    p {
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .illustration {
    z-index: 1;
    margin-top:0px;
    position: relative;

    .bg-logo {
      position: absolute;
      z-index: 0;
      height: 800px;
      right: -180px;
      top: -240px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        left: 80px;
        top: -240px;
        height: 1360px;
      }
    }
  }

  .taxdashs {
    z-index: 10;
    flex-grow: 1;
    pointer-events: none;
    position: relative;
    transition: all 300ms ease-in-out;
    /* filter: drop-shadow(0px 16px 64px rgba(0, 0, 0, 0.5))
      drop-shadow(0px 16px 128px rgba(0, 0, 0, 0.5)); */

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      img {
        width: 100%;
        margin-top: 40px;
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      ${(p) =>
        p.$fluid &&
        css`
          height: 160%;
          margin-left: -5%;
          margin-right: -20%;
          margin-top: -27%;
          margin-bottom: -35%;
        `}
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    max-height: 820px;
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-columns: minmax(460px, 1fr) 1fr;
    grid-column-gap: 32px;
    align-items: center;
  }
`;

export const VideoSection = styled.section`
  padding: 40px 0;

  h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 24px;
  }

  p {
    font-weight: 350;
    font-size: 20px;
    line-height: 28px;
    color: ${(p) => p.theme.textLightGray};
    margin: 32px 0;
  }

  ul {
    color: ${(p) => p.theme.textLightGray};
    font-size: 17px;
    line-height: 28px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 96px 0;

    ${Wrapper} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;

      h3 {
        font-size: 35px;
        line-height: 42px;
      }

      .iframe-container {
        position: relative;
        width: calc(100% - 32px);
        border-radius: 0px 20px;
        height: 0;
        /* 16:9 */
        padding-top: 56.25%;
        /* 3:4 */
        /* padding-top: 75%; */
        align-self: center;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          box-sizing: border-box;
        }
      }
/* 
      video,
      iframe {
        background: red;
        width: calc(100% - 32px);
        height: 100%;
        border-radius: 0px 20px;
      } */

      .column-1 {
        grid-column: 1;
        grid-row: 1 / 4;
        background: blue;
      }

      .column-2 {
        grid-column: 2;
      }
    }
  }
`;

export const Detalhes = styled.section`
  padding: 72px 0;
  background: ${(p) => p.theme.degradeAzul};
  text-align: center;
  color: ${(p) => p.theme.branco};

  h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    margin-bottom: 42px;
  }

  h3 + div {
    margin-bottom: 80px;
  }

  .detalhes {
    position: relative;
    margin-bottom: 32px;

    * {
      z-index: 1;
      position: relative;
    }

    img {
      margin-top: 38px;
      user-select: none;
    }

    .iconBg {
      position: absolute;
      top: -38px;
      left: 0;
      right: 0;
      z-index: 0;
      margin-left: auto;
      margin-right: auto;
    }

    h6 {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin: 16px 0 32px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    h3 {
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 56px;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }

    h3 + div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 32px;
    }
  }
`;

export const Testimonials = styled.section`
  padding: 24px 0;

  h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
  }

  p {
    font-weight: 350;
    font-size: 17px;
    line-height: 28px;
    color: ${(p) => p.theme.textLightGray};
    margin: 24px 0 48px;
  }


  @media (min-width: ${(p) => p.theme.bp.desktop}) {
    ${Wrapper} {
      display: grid;
      grid-template-columns: minmax(min-content, 368px) 1fr;
      align-items: center;
    }

    h4 {
      font-size: 35px;
      line-height: 42px;
    }

    .cta {
      margin-right: 48px;
    }
  }
`;

export const ComoFunciona = styled.section`
  background: var(--color-white);
  padding: 84px 0;

  .passo {
    margin-top: 64px;
  }

  h5 {
    font-weight: normal;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};

    span {
      font-weight: 600;
      font-size: 70px;
      line-height: 70px;
      display: block;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: ${(p) => p.theme.primary};
    margin-top: 32px;
  }

  img {
    margin: 24px auto;
    width: 100%;
  }

  p {
    font-style: normal;
    font-weight: 350;
    font-size: 17px;
    line-height: 28px;
    color: ${(p) => p.theme.textLightGray};
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    .passo {
      display: grid;
      grid-template-columns: min-content 1fr 50%;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 24px;
      align-items: center;
      margin-top: 8px;

      div:first-child {
        align-self: flex-end;
      }

      h6 {
        margin-top: 0;
        margin-bottom: 24px;
        align-self: flex-end;
        font-size: 22px;
        line-height: 29px;
      }

      h6 + img {
        margin: 0 64px;
        width: 432px;
        grid-row: 1 / span 2;
        grid-column: 3;
      }

      p {
        grid-column: 2;
        align-self: flex-start;
      }
    }

    .passo.reverse {
      grid-template-columns: 50% min-content 1fr;

      h6 + img {
        grid-row: 1 / span 2;
        grid-column: 1;
      }

      p {
        grid-column: 3;
      }
    }
  }
`;

export const Clientes = styled.section`
  padding: 40px 0;
  /* background-color: ${(p) => p.theme.bgGray}; */
  background-color: ${(p) => p.theme.branco};

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 42px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.textLightGray};
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 72px 0;

    ${Wrapper} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, min-content);
      justify-items: flex-start;
      align-items: flex-start;
      column-gap: 32px;

      h4 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        max-width: 420px;
        margin-bottom: 54px;
      }

      h4 + div {
        grid-row: 1 / span 4;
        grid-column: 2;
        justify-self: stretch;
        align-self: flex-start;
      }

      .column-1 {
        grid-column: 1;
      }
    }
  }
`;

export const Faq = styled.section`
  padding: 64px 0;
  background: ${(p) => p.theme.bgGray};

  h5.title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 32px;
  }

  p.duvidas {
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-top: 40px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 100px 0;

    p.duvidas {
      margin-top: 86px;

      div {
        margin-left: 16px;
      }
    }
  }
`;

export const Solucoes = styled.section`
  padding: 42px 0 88px;
  background: ${(p) => p.theme.bgGray};
  overflow: hidden;

  h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: ${(p) => p.theme.primary};
    margin-bottom: 44px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 120px 0;

    h5 {
      font-size: 35px;
      line-height: 36px;
      margin-bottom: 64px;
    }
  }
`;

export const Final = styled.section`
  padding: 52px 0;
  background: var(--color-white);
  text-align: center;

  h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 44px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 84px 0;

    h5 {
      font-size: 36px;
      margin-bottom: 56px;
      max-width: 520px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
